import { createStore } from 'vuex'
import Auth from './auth';

export default createStore({
    state: {
        // count: 10,
        // todos: [],
        // user: null,
    },
    getters: {
        // completedTodosCount(state) {
        //     return state.todos.filter(todo => todo.completed).length;
        // },
    },
    mutations: {
        // increment(state) {
        //     state.count++;
        //     console.log(state.count);
        // },
        // addTodo(state, text) {
        //     state.todos.push({ text, completed: false });
        // },
        // setUser(state) {
        //     console.log("user setted");
        // }
    },
    actions: {
        // fetchUser({ commit }) {
        //     commit('setUser');
        // },
    },
    modules: {
        auth: Auth
    }
})
