<template>
    <div class="container py-5">
        <br>
        <div class="alert alert-success my-5">
            আপনার অনুরোধ সফলভাবে জমা দেওয়া হয়েছে. আপনার সফ্টওয়্যার আবার সক্রিয় হতে 3-4 ঘন্টা সময় লাগবে। 3-4 ঘন্টা পর আপনার সফটওয়্যারটিতে লগইন করুন এবং চেক করে দেখুন।
        </div>
        <div class="text-center">
            <router-link to="/">Go back to Home</router-link>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';

export default {
    setup() {
        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0
        })

        return { }
    }
};
</script>

<style lang="scss" scoped>

a, div {
    font-size: 20px;
    text-decoration: none;
}

</style>