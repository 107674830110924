<template>
    <div class="container pt-5">
        <Banner />
        <About />
    </div>
</template>

<script>
import Banner from "./Banner.vue"
import About from './About.vue'
import { onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    components: {
        Banner,
        About
    },
    setup() {
        const store = useStore()
        const router = useRouter()

        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0

            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            if (code) {
                localStorage.setItem("code", code)
            }

            // store.dispatch('auth/checkAuthentication')
            // .then((res)=>{
            //     // router.push("/diseases")
            // })
            // .catch((err)=>{
                
            // })
        })

        return {
            
        }
    }
};
</script>