<template>
    <div id="page-header">
        <nav class="navbar navbar-expand-lg fixed-top navbar-light py-0">
            <div class="container px-3">
                <router-link to="/">
                    <img src="../../assets/logo.png" alt="Unanichikitsa" height="28">
                </router-link>
                
                <div class="text-end menu-icon">
                    <router-link to="/">Home</router-link>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';

export default {
    setup() {

        return {
            
        }
    }
}
</script>

<style lang="scss" scope>
@import "src/_preset.scss";

.fixed-top {
    z-index: 4 !important;
}

.navbar {
    background-color: $cs-white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    height: 60px;
    * {
        color: $cs-primary !important;
    }

    a {
        text-decoration: none;
    }
    .menu-icon {
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        font-weight: 600 !important;
    }
}
</style>