<template>
    <div id="page-container">
        <div id="content-wrap">
            <Header></Header>
            <router-view />
        </div>
        <div id="footer">
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import Header from '@/components/includes/Header.vue'
import Footer from '@/components/includes/Footer.vue'

export default {
    components: {
        Header,
        Footer
    },
    setup() {

        return { }
    }
};
</script>

<style lang="scss">
@import "src/_preset.scss";

* {
    font-family: Roboto, sans-serif;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 3.5rem;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6.5rem;
}
</style>
