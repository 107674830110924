import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/home/Home.vue'
import Registration from '@/components/auth/Registration.vue'
import CompleteNewOrder from '@/components/auth/CompleteNewOrder.vue'
import ActivePlan from '@/components/auth/ActivePlan.vue'
import CompleteRenewOrder from '@/components/auth/CompleteRenewOrder.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/registration',
        name: 'Registration',
        component: Registration
    },
    {
        path: '/active-plan',
        name: 'Active Plan',
        component: ActivePlan
    },
    {
        path: '/complete-new-order',
        name: 'Complete New Order',
        component: CompleteNewOrder
    },
    {
        path: '/complete-renew-order',
        name: 'Complete Renew Order',
        component: CompleteRenewOrder
    },
    { 
        path: '/:pathMatch(.*)*', 
        component: Home 
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
