<template>
    <div class="container py-5">
        <form @submit.prevent="onSubmit">

            <div class="alert alert-success m-0 mt-5">
                <b>সফটওয়্যার একটিভ করুন:</b> আপনার unani chikitsa সফটওয়্যারটি পুনরায় একটিভ করুন।
            </div>
            
            <h2 class="mt-4 mb-4 py-3 title">অর্ডার সম্পূর্ণ করতে নিচের ফর্মটি পূরণ করুন।</h2>

            <!-- <div class="alert alert-success mb-5">
                <b>First Order:</b> If you are ordering for the first time then you have to pay <b>500 TK</b> for registration.
            </div> -->

            <!-- <div class="form-check pt-3">
                <label class="form-check-label"><input class="form-check-input" type="checkbox" name="option1" value="something" v-model="isFirstOrder"> <span class="ms-2">This is my first order</span></label>
            </div> -->

            <div class="row mb-2">
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label class="mb-2" for="full-name"><b>Full name</b></label>
                        <input type="text" class="form-control" id="full-name" v-model="name" required
                            placeholder="Enter your name">
                    </div>
                    <br>
                    <div class="form-group">
                        <label class="mb-2" for="phone-number"><b>Phone number</b></label>
                        <input type="text" class="form-control" id="phone-number" v-model="phoneNumber" required
                            placeholder="Enter phone number">
                    </div>
                </div>
            </div>

            <!-- <div v-if="isFirstOrder == true" class="pb-3 pt-4">
                <div class="alert alert-success m-0 mt-3">
                    <b>Create New Gmail:</b> You need to enter your gmail and password of your gmail to prepare your software. But it is better to create a new gmail.
                </div>
            </div> -->

            <div class="row mt-4 mb-2">
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label class="mb-2" for="email"><b>Email address</b></label>
                        <input type="email" class="form-control" id="email" v-model="email" required
                            placeholder="Enter email address" >
                    </div>
                    <div v-if="isFirstOrder == true" class="form-group mt-4">
                        <label class="mb-2" for="password"><b>Password</b></label>
                        <input type="text" class="form-control" id="password" v-model="password" required
                            placeholder="Enter email password" >
                    </div>
                    <div v-if="isFirstOrder == false" class="form-group mt-4">
                        <label class="mb-2" for="software-url"><b>Software URL</b></label>
                        <input type="text" class="form-control" id="software-url" v-model="softwareUrl" required
                            placeholder="Enter software url">
                    </div>
                </div>
            </div>

            <div class="row mt-4 mb-2">
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label class="mb-2" for="plans"><b>Plans And Pricing</b></label>
                        <select v-model="selectedPlan" @change="calculateTotalOrderPrice" class="form-control" required id="plans">
                            <option value="">Choose one</option>
                            <template v-for="(plan, index) in plans" :key="index">
                                <option :value="plan.planName">
                                    {{ plan.planName }}
                                </option>
                            </template>
                        </select>
                    </div>
                </div>
            </div>

            <div class="alert alert-success mb-4 mt-5">
                <div class="mb-2" v-if="selectedPlan != '' && isFirstOrder == true" >
                    <p class="d-inline">Registration Fee 500 TK</p>
                    <p class="d-inline"><h4 class="d-inline"><b class="px-2">+</b></h4></p>
                    <template v-for="(plan, index) in plans" :key="index">
                        <p class="d-inline" v-if="selectedPlan == plan.planName">{{ plan.planName }}</p>
                    </template>
                    <p class="d-inline"><h4 class="d-inline"><b class="px-2" >=</b></h4>{{ totalOrderPrice }} TK</p>
                </div>

                <div><b>অর্ডার নিশ্চিত করুন:</b> আপনার অর্ডার নিশ্চিত করতে নিচের নম্বরে <b>{{ totalOrderPrice }} TK</b> পাঠান। <br>Bkash / Nagad / Rocket: 01737354497 (Personal).</div>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 pb-4 pt-3">
                    <div class="form-group">
                        <label class="mb-2" for="payment-method"><b>Payment Method</b></label>
                        <select v-model="paymentMethod" class="form-control" required id="payment-method">
                            <option value="">Choose one</option>
                            <option value="Bkash">Bkash</option>
                            <option value="Nagad">Nagad</option>
                            <option value="Rocket">Rocket</option>
                        </select>
                    </div>
                    <br>
                    <div class="form-group">
                        <label class="mb-2" for="transection-id"><b>Last 3 Digits Of Your Bkash / Nagad / Rocket Number</b></label>
                        <input type="text" class="form-control" id="transection-id" v-model="transectionId" required
                            placeholder="Enter 3 digits">
                    </div>
                </div>
            </div>

            <div class="mt-5 mb-2">
                <button type="submit" class="btn btn-primary">Submit <span v-if="spinner" class="spinner"></span></button>
            </div>
        </form>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { toast } from 'vue3-toastify';

export default {
    setup() {
        const name = ref('')
        const phoneNumber = ref('')
        const BBUASMRegistrationNumber = ref('')
        const email = ref('')
        const password = ref('')
        const selectedPlan = ref('')
        const isFirstOrder = ref(false)
        const softwareUrl = ref('')
        const paymentMethod = ref('')
        const transectionId = ref('')

        const totalOrderPrice = ref(0)

        const spinner = ref(false)

        const store = useStore();
        const router = useRouter();

        const plans = ref([
            {
                planName: '3 Months (250 TK)',
                price: 250
            },
            {
                planName: '6 Months (350 TK)',
                price: 350
            },
            {
                planName: '1 Year (450 TK)',
                price: 450
            },
            {
                planName: '5 Years (1650 TK)',
                price: 1650
            }
        ])

        onMounted(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0
        })

        const onSubmit = () => {
            let code = localStorage.getItem("code")
            spinner.value = true

            let info = {
                name: name.value,
                phoneNumber: phoneNumber.value,
                BBUASMRegistrationNumber: BBUASMRegistrationNumber.value,
                email: email.value,
                password: password.value,
                isFirstOrder: isFirstOrder.value,
                softwareUrl: softwareUrl.value,
                selectedPlan: selectedPlan.value,
                paymentMethod: paymentMethod.value,
                transectionId: transectionId.value,
                totalOrderPrice: totalOrderPrice.value,
                status: 1,
                year: new Date().getFullYear(),
                month: new Date().getMonth()+1,
                day: new Date().getDate(),
                date: Date.now(),
                promoCode: code != null ? code : ''
            }

            store.dispatch("auth/saveOrderInfo", info)
                .then((res) => {
                    spinner.value = false

                    name.value = ''
                    phoneNumber.value = ''
                    BBUASMRegistrationNumber.value = ''
                    email.value = ''
                    password.value = ''
                    isFirstOrder.value = false
                    softwareUrl.value = ''
                    selectedPlan.value = ''
                    paymentMethod.value = ''
                    transectionId.value = ''
                    totalOrderPrice.value = 0
                    
                    /*toast("Your request has been successfully submitted. It will take 3-4 days for your software to be ready. We will contact you when it is ready.", {
                        "theme": "colored",
                        "type": "success",
                        "autoClose": 15000,
                        "transition": "flip",
                        "dangerouslyHTMLString": true
                    })*/
                    router.push("/complete-renew-order")
                })
                .catch((err) => {
                    spinner.value = false
                    toast("Something went wrong, try again later.", {
                        "theme": "colored",
                        "type": "error",
                        "autoClose": 3000,
                        "transition": "flip",
                        "dangerouslyHTMLString": true
                    })
                })
        }

        watch(isFirstOrder, (newValue, oldValue) => {
            calculateTotalOrderPrice()

            password.value = ''
            softwareUrl.value = ''
        });

        function calculateTotalOrderPrice() {
            totalOrderPrice.value = 0
            for (let index = 0; index < plans.value.length; index++) {
                if (plans.value[index].planName == selectedPlan.value) {
                    totalOrderPrice.value = plans.value[index].price
                }
            }

            totalOrderPrice.value = isFirstOrder.value ? totalOrderPrice.value + 500 : totalOrderPrice.value
        }

        return { plans, name, email, password, phoneNumber, BBUASMRegistrationNumber, isFirstOrder, softwareUrl, selectedPlan, paymentMethod, transectionId, spinner, totalOrderPrice, onSubmit, calculateTotalOrderPrice };
    }
};
</script>

<style lang="scss" scoped>
@import "src/_preset.scss";

.title {
    font-weight: 500;
}

label, div, input, select {
    font-size: 18px;
}


.form-check {
    input {
        height: 24px !important;
        width: 24px !important;
        border: 1px solid $cs-link;
    }
    span {
        position: relative;
        top: 5px !important;
        font-weight: 600;
    }
}

.btn.btn-primary {
    padding-top: 8px;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 18px;
}
</style>