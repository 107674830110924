import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCU8I3_4qMUrGrWXBX_a4PmpVSw3My5jus",
    authDomain: "unanichikitsalive.firebaseapp.com",
    projectId: "unanichikitsalive",
    storageBucket: "unanichikitsalive.appspot.com",
    messagingSenderId: "802321746207",
    appId: "1:802321746207:web:39f2138a98982d3340482a",
    measurementId: "G-2WEKLZESVB"
}

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);

export {
    db
}

export {
    auth
}